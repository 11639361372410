interface DeviationGroup {
  deviationsCount: number,
  deviationsCycles: number[],
  unitsWithDeviations: number,
}

export const buildChartOptionsObjects = (chartsList: DeviationGroup[]): unknown[] => {
  return chartsList.map(chart => {
    const { deviationsCount, deviationsCycles, unitsWithDeviations } = chart
    return {
      title: {
        text: `${deviationsCount} deviation/s. (Total: ${unitsWithDeviations})`,
      },
      xAxis: {
        type: 'value',
      },
      yAxis: {
        name: 'CT',
        type: 'category',
        data: ['0:00-2:30', '2:30-5:00', '5:00-7:30', '7:30-'],
      },
      series: [
        {
          label: {
            show: true,
          },
          data: deviationsCycles,
          type: 'bar',
        },
      ],
    }
  })
}
