import { defineStore } from 'pinia'
import type { OverviewQueryStore } from './types'
import { useUsersStore } from '@/stores/users/users'

const userStore = useUsersStore()

const getTimestamp = (date: string) => {
  const dateValue = new Date(date).valueOf()
  return dateValue / 1000
}

const getTimestampMinus3Days = (date: string) => {
  const dateValue = new Date(date).valueOf() - 86400000 * 3 /* 1 day in ms */
  return dateValue / 1000
}

const getTimestampPlus1Day = (date: string) => {
  const dateValue = new Date(date).valueOf() + 86400000 /* 1 day in ms */
  return dateValue / 1000
}

enum GroupByOptions {
  bySessions = 'sessions',
  byProcedures = 'procedures',
  byStations = 'stations',
}

export const useOverviewQueryStore = defineStore('overviewQueryStore', {
  state: (): OverviewQueryStore => ({
    procedure: '',
    factory: '',
    groupBy: '',

    from: '',
    to: '',
  }),

  getters: {
    isGrouppedByStations: (state) => state.groupBy === GroupByOptions.byStations,
    isGrouppedBySessions: (state) => state.groupBy === GroupByOptions.bySessions,
    isGrouppedByProcedures: (state) => state.groupBy === GroupByOptions.byProcedures,

    sessionQueryFilters: (state) => {
      const userGroup = userStore.userGroup

      const filtersList: unknown[] = []

      if (userGroup) filtersList.push({ manufacturer: { $in: userGroup } })
      if (state.from) filtersList.push({ 'lastModified.seconds': { $gte: getTimestampMinus3Days(state.from) } })
      if (state.to) filtersList.push({ 'lastModified.seconds': { $lte: getTimestampPlus1Day(state.to) } })
      if (state.procedure) filtersList.push({ procedure: { $regex: `.*${state.procedure}.*` } })
      if (state.factory) filtersList.push({ source: { $regex: `.*${state.factory}.*` } })

      return JSON.stringify({ $and: filtersList })
    },

    procedureQueryFilters: () => {
      const userGroup = userStore.userGroup
      return JSON.stringify({
        $and: [{
          manufacturer: { $in: userGroup },
        }],
      })
    },

    unitsQueryFilters: (state) => {
      const userGroup = userStore.userGroup

      const filtersList: unknown[] = []

      if (userGroup) filtersList.push({ manufacturer: { $in: userGroup } })
      if (state.from) filtersList.push({ 'created.seconds': { $gte: getTimestamp(state.from) } })
      if (state.to) filtersList.push({ 'created.seconds': { $lte: getTimestamp(state.to) } })
      if (state.procedure) filtersList.push({ procedure: { $regex: `.*${state.procedure}.*` } })
      // TODO: Units don't have the factory key?
      // How this should be treated?
      // if (state.factory) filtersList.push({ source: { $regex: `.*${state.factory}.*` } })

      return JSON.stringify({ $and: filtersList })
    },
  },

  actions: {
    setProcedureQuery(payload: string) {
      this.procedure = payload
    },

    setFactoryQuery(payload: string) {
      this.factory = payload
    },

    setFromQuery(payload: string) {
      if (payload.includes('Z')) {
        this.from = payload
      } else {
        const isoDate = `${payload}:00.000Z`
        this.from = isoDate
      }
    },

    setToQuery(payload: string) {
      if (payload.includes('Z')) {
        this.to = payload
      } else {
        const isoDate = `${payload}:00.000Z`
        this.to = isoDate
      }
    },

    setGrouppingBy(payload: string) {
      this.groupBy = payload
    },
  },
})
