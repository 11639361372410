const HOURS_IN_DAY = 24
const MINUTES_IN_HOUR = 60
const SECONDS_IN_MINUTE = 60
const MILLISECONDS_IN_SECOND = 1000

export const getInitialEndFilterTime = (): string => {
  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth() + 1
  const currentDate = now.getDate()
  const currentHour = now.getHours()

  return new Date(`${currentYear}-${currentMonth}-${currentDate} ${currentHour}:00:00`).toISOString()
}

export const getInitialStartFilterTime = (): string => {
  const endDateString = getInitialEndFilterTime()
  const endDate = new Date(endDateString).getTime()

  const startDate = new Date(endDate - (HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND))
  const startDateYear = startDate.getFullYear()
  const startDateMonth = startDate.getMonth() + 1
  const startDateDate = startDate.getDate()
  const startDateHour = startDate.getHours()

  return new Date(`${startDateYear}-${startDateMonth}-${startDateDate} ${startDateHour}:00:00`).toISOString()
}
