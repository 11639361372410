import { api } from '@/main'
import { defineStore } from 'pinia'

import type { AiSession } from '@/api/sessions'
import type { AiProcedure } from '@/api/procedures'
import type { AiUnit, UnitsApiUnitsListUnitsRequest } from '@/api/units'

import type {
  SessionArguments,
  UnitsArguments,
  OverviewStoreState,
  GroupedByProceduresResponse,
  GroupedBySessionsResponse,
  GroupedByStationsResponse,
} from './types'

import { getReadableDurationFromSeconds } from '@/utils/duration'

interface DeviationGroup {
  deviationsCount: number,
  deviationsCycles: number[],
  unitsWithDeviations: number,
}

const DEVIATIONS_CYCLES = {
  TWO_AND_HALF_MINUTES: 150,
  FIVE_MINUTES: 300,
  SEVEN_AND_HALF_MINUTES: 450,
}

interface UnitsFetchInterface {
  units: AiUnit[],
  nextPageToken: string,
}

interface SessionsFetchInterface {
  sessions: AiSession[],
  nextPageToken: string,
}

interface SessionHashMap {
  [key: string] : AiSession
}

const DEFAULT_PAGE_SIZE = 500

const fetchSessions = async (...args: SessionArguments): Promise<SessionsFetchInterface> => {
  try {
    const { data: { sessions, nextPageToken } } = await api.sessions.sessionsListSessions(...args)

    return { sessions: sessions as AiSession[], nextPageToken: nextPageToken as string }
  } catch (error) {
    console.error(error)

    return {
      sessions: [],
      nextPageToken: '',
    }
  }
}

const fetchUnits = async (...args: UnitsArguments): Promise<UnitsFetchInterface> => {
  try {
    const { data: { units, nextPageToken } } = await api.units.unitsListUnits(...args)

    return { units: units as AiUnit[], nextPageToken: nextPageToken as string }
  } catch (error) {
    console.error(error)

    return {
      units: [],
      nextPageToken: '',
    }
  }
}

export const useOverviewAnalyticsStore = defineStore('overviewAnalyticsStore', {
  state: (): OverviewStoreState => ({
    areSessionsLoading: true,
    sessions: [],

    areProceduresLoading: true,
    procedures: [],

    areUnitsLoading: true,
    units: [],
  }),

  getters: {
    sessionsCount: (state) => state.sessions.length,
    unitsCount: (state) => state.units.length,
    sessionsFromUnit: (state): AiSession[] => {
      const data = state.units.reduce((memo, unit) => {
        const sessionName = unit?.name?.split('/')[1] || ''
        memo[sessionName] = {
          name: sessionName,
        }
        return memo
      }, {} as SessionHashMap)
      return Object.values(data)
    },
    isDataLoading: (state) => state.areSessionsLoading || state.areProceduresLoading || state.areUnitsLoading,

    groupedBySessions: (state) => {
      const groupOfSessions: GroupedBySessionsResponse[] = []

      for (const session of state.sessions) {
        const { name, procedure, station, duration } = session
        const sessionDuration = getReadableDurationFromSeconds(duration as string)
        const unitsBySession = state.units.filter(unit => unit.name?.includes(name as string))
        if (unitsBySession.length > 0) {
          groupOfSessions.push({
            sessionName: name?.split('/')[1] as string,
            procedureName: procedure?.split('/')[1] as string,
            stationName: station?.split('/')[1] as string,
            duration: sessionDuration,
            unitsCount: unitsBySession.length,
          })
        }
      }

      return groupOfSessions
    },

    groupedByStations: (state) => {
      const stationsFromSessions = state.sessions.map(session => session.station)
      const uniqueStationsList = new Set(stationsFromSessions)

      const groupOfStations: GroupedByStationsResponse[] = []

      for (const station of uniqueStationsList) {
        let unitsCount = 0
        let unitsDeviationsCount = 0
        let unitsNotificationsCount = 0

        if (!station) continue

        const sessionsList = state.sessions.filter(session => session.station === station)
        const sessionsIdsList = sessionsList.map(session => {
          if (!session.name) return null
          return session.name.split('/')[1]
        }).filter(sessionId => !!sessionId)

        for (const unit of state.units) {
          if (!unit) continue

          const unitSessionId = unit.name?.split('/')[1]
          if (!unitSessionId) continue

          if (sessionsIdsList.includes(unitSessionId)) {
            const { deviationCount, wasNotified } = unit
            unitsCount += 1
            if (deviationCount) unitsDeviationsCount += 1
            if (wasNotified) unitsNotificationsCount += 1
          }
        }

        if (unitsCount > 0) {
          groupOfStations.push({
            stationName: station.split('/')[1],
            unitsCount,
            unitsDeviationsPercentage: Number(unitsDeviationsCount * 100 / unitsCount).toFixed(2),
            unitsNotificationsPercentage: Number(unitsNotificationsCount * 100 / unitsCount).toFixed(2),
          })
        }
      }

      return groupOfStations
    },

    groupedByProcedures: (state) => {
      const proceduresFromSession = state.sessions.map(session => session.procedure)
      const uniqueProceduresList = new Set(proceduresFromSession)

      const groupOfProcedures: GroupedByProceduresResponse[] = []
      for (const procedure of uniqueProceduresList) {
        if (!procedure) continue

        const procedureUnits = state.units.filter(unit => unit.procedure === procedure)
        if (!procedureUnits.length) continue

        const stationUnit = procedureUnits[0]
        const stationName = (state.sessions.find(session => stationUnit.name?.includes(session.name as string)) as AiSession).station?.split('/')[1]

        let unitsCount = 0
        let unitsDeviationsCount = 0
        let unitsNotificationsCount = 0

        for (const unit of procedureUnits) {
          const { wasNotified, deviationCount } = unit
          unitsCount += 1
          if (deviationCount) unitsDeviationsCount += 1
          if (wasNotified) unitsNotificationsCount += 1
        }

        groupOfProcedures.push({
          stationName: stationName as string,
          procedureName: procedure,
          unitsCount,
          unitsDeviationPercentage: Number(unitsDeviationsCount * 100 / unitsCount).toFixed(2),
          unitsNotificationsPercentage: Number(unitsNotificationsCount * 100 / unitsCount).toFixed(2),
        })
      }

      return groupOfProcedures
    },

    deviationsChartsData: (state) => {
      const unitsDeviationsAndCycles = state.units.map(unit => ({
        deviationsCount: unit.deviationCount,
        cycleTime: Number(unit?.cycleTime?.split('.')[0]),
      }))

      const groupedListOfDeviations: DeviationGroup[] = []

      const parseCycleTime = (cycleTime: number): number[] => {
        const cyclesArray = [0, 0, 0, 0]

        if (cycleTime <= DEVIATIONS_CYCLES.TWO_AND_HALF_MINUTES) cyclesArray[0]++
        if (cycleTime > DEVIATIONS_CYCLES.TWO_AND_HALF_MINUTES && cycleTime <= DEVIATIONS_CYCLES.FIVE_MINUTES) cyclesArray[1]++
        if (cycleTime > DEVIATIONS_CYCLES.FIVE_MINUTES && cycleTime <= DEVIATIONS_CYCLES.SEVEN_AND_HALF_MINUTES) cyclesArray[2]++
        if (cycleTime > DEVIATIONS_CYCLES.SEVEN_AND_HALF_MINUTES) cyclesArray[3]++

        return cyclesArray
      }

      unitsDeviationsAndCycles.forEach(({ deviationsCount, cycleTime }) => {
        const isGivenDeviationsGrouped = groupedListOfDeviations.some(group => group.deviationsCount === deviationsCount)
        const parsedCycleTime = parseCycleTime(cycleTime)

        if (isGivenDeviationsGrouped) {
          const unitGroup = groupedListOfDeviations.find(group => group.deviationsCount === deviationsCount)
          if (!unitGroup) return

          for (let deviationIndex = 0; deviationIndex < unitGroup.deviationsCycles.length; deviationIndex++) {
            unitGroup.deviationsCycles[deviationIndex] += parsedCycleTime[deviationIndex]
          }

          unitGroup.unitsWithDeviations++
        } else {
          groupedListOfDeviations.push({
            deviationsCount: deviationsCount || 0,
            deviationsCycles: parsedCycleTime,
            unitsWithDeviations: 1,
          })
        }
      })

      return groupedListOfDeviations.sort((a, b) => a.deviationsCount - b.deviationsCount)
    },
  },

  actions: {
    setSessions(payload: AiSession[]) {
      this.sessions = payload
    },

    setProcedures(payload: AiProcedure[]) {
      this.procedures = payload
    },

    setUnits(payload: AiUnit[]) {
      this.units = payload
    },

    async getAllSessions(filters: Record<string, unknown>) {
      this.sessions = []
      this.areSessionsLoading = true

      let shouldStopRequestingMore = false
      let sessionsNextPageToken = '1'

      while (!shouldStopRequestingMore) {
        const response = await fetchSessions({ ...filters, pageToken: sessionsNextPageToken })

        if (response.sessions.length) {
          sessionsNextPageToken = response.nextPageToken
          const currentSessionsList = this.sessions
          const mergedSessionsList = currentSessionsList.concat(response.sessions as AiSession[])

          this.setSessions(mergedSessionsList)

          if (response.sessions.length < DEFAULT_PAGE_SIZE) {
            this.areSessionsLoading = false
            shouldStopRequestingMore = true
          }
        } else {
          this.areSessionsLoading = false
          shouldStopRequestingMore = true
        }
      }
    },

    async getAllProcedures(filters: Record<string, unknown>) {
      this.procedures = []
      this.areProceduresLoading = true

      try {
        const { data: { procedures } } = await api.procedures.proceduresListProcedures(filters)
        this.setProcedures(procedures as AiProcedure[])
      } catch (error) {
        console.error(error)
      } finally {
        this.areProceduresLoading = false
      }
    },

    async getAllUnits(filters: UnitsApiUnitsListUnitsRequest) {
      this.units = []
      this.areUnitsLoading = true

      let shouldStopRequestingMore = false
      let unitsNextPageToken = '1'

      while (!shouldStopRequestingMore) {
        const response = await fetchUnits({ ...filters, pageToken: unitsNextPageToken })

        if (response.units.length) {
          unitsNextPageToken = response.nextPageToken
          const currentUnitsList = this.units
          const mergedUnitsList = currentUnitsList.concat(response.units)

          this.setUnits(mergedUnitsList as AiUnit[])

          if (response.units.length < DEFAULT_PAGE_SIZE) {
            shouldStopRequestingMore = true
            this.areUnitsLoading = false
          }
        } else {
          shouldStopRequestingMore = true
          this.areUnitsLoading = false
        }
      }
    },
  },
})
